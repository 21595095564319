import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectEmail } from '../../redux/slice/authSlice'


const AdminOnlyRoute = ({children}) => {
    const userEmail = useSelector(selectEmail)
    if (userEmail === "butternegg.co@gmail.com"){
    return children
    }
  return (
    <section style={{height: "80vh"}}>
      <div className="container">
        <h2>ERROR 404</h2>
        <p>THIS PAGE IS NOT FOUND.</p>
        <br />
        <Link to="/">
        <button className="--btn">&larr; BACK</button>
        </Link>
      </div>
    </section>
  )
}
export const AdminOnlyLink = ({children}) => {
  const userEmail = useSelector(selectEmail)
  if (userEmail === "butternegg.co@gmail.com"){
  return children
  }
return null
}
export default AdminOnlyRoute